import styled, {keyframes} from 'styled-components';
import {pxToRem} from '../../utils/pxToRem';
import * as Accordion from '@radix-ui/react-accordion';
import Arrow from '../../../static/icons/chevron-svgrepo-com.svg';

export const TableRow = styled.tr`
  transition: background-color 250ms ease-in-out;
  &:hover {
    background-color: #f6f6f6;
  }
`;

export const Cell = styled.td<{ align: 'left' | 'right' | 'center'; width?: number }>`
  text-align: ${(p) => p.align};
  padding: ${pxToRem(6)};
  width: ${(p) => (p.width ? pxToRem(p.width) : 'auto')};
  @media (max-width: ${pxToRem(480)}) {
    font-size: ${pxToRem(12)};
    padding: ${pxToRem(4)};
  }
`;

export const open = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

export const close = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

export const AccordionRoot = styled(Accordion.Root)`
  border-radius: ${pxToRem(25)};
  overflow: hidden;
`;

export const Icon = styled(Arrow)`
  fill: var(--color-secondary);
  transition: transform ease-in-out 300ms;
`;

export const AccordionTrigger = styled(Accordion.Trigger)`
  width: 100%;
  padding: ${pxToRem(16)} ${pxToRem(24)} ${pxToRem(12)} ${pxToRem(24)};
  text-transform: uppercase;
  text-align: left;
  font-size: ${pxToRem(16)};
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: color 250ms ease-in-out;
  display: flex;
  justify-content: space-between;

  background-color: var(--gray-light);
  box-sizing: border-box;

  color: var(--color-primary);

  &:hover {
    color: var(--color-secondary);
  }
  &[data-state='open'] {
    color: var(--color-secondary);
    border-bottom-style: solid;
  }

  &:hover ${Icon} {
    fill: var(--color-secondary);
  }
  &[data-state='open'] ${Icon} {
    transform: rotate(180deg);
  }
`;

export const AccordionItem = styled(Accordion.Item)`
  &:not(:last-child) ${AccordionTrigger} {
    border-bottom: 2px dashed #fff;
  }
  & ${AccordionTrigger}[data-state="open"] {
    border-bottom: 2px solid #fff;
  }
`;

export const AccordionHeader = styled(Accordion.Header)`
  margin: 0;
`;

export const AccordionContent = styled(Accordion.Content)`
  overflow: hidden;

  &[data-state='open'] {
    animation: ${open} 300ms ease-out;
  }
  &[data-state='closed'] {
    animation: ${close} 300ms ease-out;
  }
`;

export const AccordionContentInner = styled.div`
  padding: ${pxToRem(12)};
  position: relative;
  max-width: 100%;
  @media (max-width: ${pxToRem(480)}) {
    padding: ${pxToRem(6)};
  }
`;
