import React, { FC, useContext, useEffect, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import {
    ContentfulCennikPozycjaConnection,
    ContentfulPageCennik,
    ContentfulPageKontakt,
    ContentfulPageRehabilitacja,
    ContentfulPoradniaConnection,
    ContentfulRehabilitacjaWpisConnection,
    ContentfulSpecjalisciConnection,
} from '../model/generated/graphql';

import { PageTransition } from '../components/PageTransition/PageTransition';

import { PageContentWrapper } from '../components/PageContentWrapper/PageContentWrapper';
import { Container } from '../components/Container/Container';

import { Aside, AsideServices, AsideServicesItem, RecentPostsHeader, SidebarLink } from '../templates/serviceTemplate';
import { PageRow } from '../components/PageRow/PageRow';
import ReactTooltip from 'react-tooltip';
import Bundle from '../../static/icons/bundle.svg';

import {
    AccordionContent,
    AccordionContentInner,
    AccordionHeader,
    AccordionItem,
    AccordionRoot,
    AccordionTrigger,
    Cell,
    Icon,
    TableRow,
} from '../modules/pricing/pricingComponents';
import {
    ContentfulRichTextGatsbyReference,
    renderRichText,
    RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { pxToRem } from '../utils/pxToRem';
import EmailIcon from '../../static/icons/email_black_24dp.svg';
import PhoneIcon from '../../static/icons/call_black_24dp.svg';
import LocaleLink from '../components/LocaleLink/LocaleLink';
import { ROUTES } from '../utils/routes';
import Seo from '../components/Seo/Seo';
import LocaleContext from '../context/Locale';
import { ArticleLayout } from '../components/News/NewsTemplateComponents';

interface PricingQueryProps {
    contentfulPageCennik: ContentfulPageCennik;
    allContentfulCennikPozycja: ContentfulCennikPozycjaConnection;
    contentfulPageKontakt: ContentfulPageKontakt;
    allContentfulSpecjalisci: ContentfulSpecjalisciConnection;
    allContentfulPoradnia: ContentfulPoradniaConnection;
    contentfulPageRehabilitacja: ContentfulPageRehabilitacja;
    allContentfulRehabilitacjaWpis: ContentfulRehabilitacjaWpisConnection;
}
type PricingPageProps = PageProps<PricingQueryProps>;

const PricingPage: FC<PricingPageProps> = ({ data, location }) => {
    const [categories, setCategories] = useState([]);
    const { activeLocale } = useContext(LocaleContext);

    const { edges } = data.allContentfulCennikPozycja;

    useEffect(() => {
        let prepFilters = [];
        edges.forEach((el) => {
            if (!prepFilters.includes(el.node.category)) {
                prepFilters.push(el.node.category);
            }
        });
        setCategories(prepFilters);
    }, []);
    return (
        <>
            <Seo locale={activeLocale} seo={data.contentfulPageCennik.seo} />
            <PageContentWrapper title={data.contentfulPageCennik.title}>
                <Container mobile={true}>
                    <PageTransition>
                        <PageRow>
                            <ArticleLayout>
                                <div>
                                    <h2>{data.contentfulPageCennik.tytuSekcjiRehabilitacja}</h2>
                                    <AccordionRoot type={'multiple'}>
                                        <AccordionItem value={'kosultacja'}>
                                            <AccordionHeader>
                                                <AccordionTrigger>
                                                    {data.contentfulPageCennik.rehabilitacjaKonsultacjaTytu}{' '}
                                                    <Icon width={20} />
                                                </AccordionTrigger>
                                            </AccordionHeader>
                                            <AccordionContent>
                                                <AccordionContentInner>
                                                    {renderRichText(
                                                        data.contentfulPageCennik
                                                            .rehabilitacjaKonsultacjaOpis as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                                                    )}
                                                </AccordionContentInner>
                                            </AccordionContent>
                                        </AccordionItem>
                                        {categories.map((cat) => {
                                            return (
                                                <AccordionItem key={cat} value={cat}>
                                                    <AccordionHeader>
                                                        <AccordionTrigger>
                                                            {cat} <Icon width={20} />
                                                        </AccordionTrigger>
                                                    </AccordionHeader>
                                                    <AccordionContent>
                                                        <AccordionContentInner>
                                                            <table style={{ width: '100%', maxWidth: '100%' }}>
                                                                <tr>
                                                                    <Cell align={'left'} scope="col">
                                                                        <strong>
                                                                            {data.contentfulPageCennik.tabelaZabieg}
                                                                        </strong>
                                                                    </Cell>
                                                                    <Cell align={'center'} scope="col" width={60}>
                                                                        <strong>
                                                                            {data.contentfulPageCennik.tabelaNfz}
                                                                        </strong>
                                                                    </Cell>
                                                                    <Cell align={'center'} scope="col" width={40}>
                                                                        <Bundle
                                                                            width={32}
                                                                            fill="#36355D"
                                                                            style={{ position: 'relative' }}
                                                                            data-tip={`${data.contentfulPageCennik.megaPakietNagwek}: <br/> ${data.contentfulPageCennik.megaPakietOpis}`}
                                                                        />
                                                                        <ReactTooltip
                                                                            multiline={true}
                                                                            effect={'solid'}
                                                                            wrapper={'span'}
                                                                            className={'tooltip'}
                                                                        />
                                                                    </Cell>
                                                                    <Cell align={'right'} scope="col" width={72}>
                                                                        <strong style={{ whiteSpace: 'nowrap' }}>
                                                                            {data.contentfulPageCennik.tabela1Zabieg}
                                                                        </strong>
                                                                    </Cell>
                                                                    <Cell align={'right'} scope="col" width={92}>
                                                                        <strong>
                                                                            {data.contentfulPageCennik.tabelaPakiet}{' '}
                                                                            <br />
                                                                            <sub style={{ whiteSpace: 'nowrap' }}>
                                                                                {
                                                                                    data.contentfulPageCennik
                                                                                        .tabela10Zabiegow
                                                                                }
                                                                            </sub>
                                                                        </strong>
                                                                    </Cell>
                                                                </tr>
                                                                {data.allContentfulCennikPozycja.edges.map((el) => {
                                                                    if (el.node.category === cat) {
                                                                        console.log(el);
                                                                        return (
                                                                            <TableRow key={el.node.contentful_id}>
                                                                                <Cell align={'left'}>
                                                                                    {el.node.label}
                                                                                </Cell>
                                                                                <Cell align={'center'}>
                                                                                    {el.node.nfz && <span>✓</span>}
                                                                                </Cell>
                                                                                <Cell align={'center'}>
                                                                                    {el.node.megaBundle && (
                                                                                        <span>✓</span>
                                                                                    )}
                                                                                </Cell>
                                                                                <Cell align={'right'}>
                                                                                    {el.node.priceUnit}{' '}
                                                                                    {data.contentfulPageCennik.currency}
                                                                                </Cell>
                                                                                <Cell align={'right'}>
                                                                                    {el.node.priceBundle
                                                                                        ? `${el.node.priceBundle} ${data.contentfulPageCennik.currency}`
                                                                                        : '—'}
                                                                                </Cell>
                                                                            </TableRow>
                                                                        );
                                                                    }
                                                                })}
                                                            </table>
                                                        </AccordionContentInner>
                                                    </AccordionContent>
                                                </AccordionItem>
                                            );
                                        })}
                                    </AccordionRoot>

                                    <h2 style={{ marginTop: pxToRem(36) }}>
                                        {data.contentfulPageCennik.tytuSekcjiSpecjalici}
                                    </h2>
                                    <AccordionRoot type={'multiple'}>
                                        {data.allContentfulPoradnia.edges.map((cat) => {
                                            return (
                                                <AccordionItem key={cat.node.id} value={cat.node.id}>
                                                    <AccordionHeader>
                                                        <AccordionTrigger>
                                                            {cat.node.title} <Icon width={20} />
                                                        </AccordionTrigger>
                                                    </AccordionHeader>
                                                    <AccordionContent>
                                                        <AccordionContentInner>
                                                            <table style={{ width: '100%', maxWidth: '100%' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <Cell align={'left'} scope="col">
                                                                            <strong>
                                                                                {
                                                                                    data.contentfulPageCennik
                                                                                        .tabelaSpecjalista
                                                                                }
                                                                            </strong>
                                                                        </Cell>
                                                                        <Cell align={'center'} scope="col" width={60}>
                                                                            <strong>
                                                                                {data.contentfulPageCennik.tabelaNfz}
                                                                            </strong>
                                                                        </Cell>
                                                                        <Cell align={'right'} scope="col" width={100}>
                                                                            <strong style={{ whiteSpace: 'nowrap' }}>
                                                                                wizyta
                                                                            </strong>
                                                                        </Cell>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {data.contentfulPageCennik.specjalisciWCenniku.map(
                                                                        (el) => {
                                                                            if (
                                                                                el.division.some(
                                                                                    (division) =>
                                                                                        division.contentfulid ===
                                                                                        cat.node.contentfulid,
                                                                                )
                                                                            ) {
                                                                                return (
                                                                                    <TableRow key={el.contentful_id}>
                                                                                        <Cell align={'left'}>
                                                                                            {el.name}
                                                                                        </Cell>
                                                                                        <Cell align={'center'}>
                                                                                            {el.nfz ? (
                                                                                                <span>✓</span>
                                                                                            ) : (
                                                                                                '—'
                                                                                            )}
                                                                                        </Cell>
                                                                                        <Cell align={'right'}>
                                                                                            {el.cenaWizyty}{' '}
                                                                                            {
                                                                                                data
                                                                                                    .contentfulPageCennik
                                                                                                    .currency
                                                                                            }
                                                                                        </Cell>
                                                                                    </TableRow>
                                                                                );
                                                                            }
                                                                        },
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </AccordionContentInner>
                                                    </AccordionContent>
                                                </AccordionItem>
                                            );
                                        })}
                                    </AccordionRoot>
                                </div>
                                <div>
                                    <Aside>
                                        <RecentPostsHeader>
                                            {data.contentfulPageCennik.megaPakietNagwek}
                                        </RecentPostsHeader>
                                        <p>{data.contentfulPageCennik.megaPakietOpis}</p>
                                        <p style={{ marginBottom: 0 }}>
                                            <strong>{data.contentfulPageCennik.megaPakietCena}</strong>
                                        </p>
                                    </Aside>
                                    <Aside>
                                        <RecentPostsHeader>
                                            {data.contentfulPageRehabilitacja.scheduleVisitLabel}
                                        </RecentPostsHeader>
                                        <p>{data.contentfulPageRehabilitacja.scheduleVisitDescription}</p>
                                        <SidebarLink href={`tel:${data.contentfulPageKontakt.email}`}>
                                            <EmailIcon width={18} fill="#36355D" />
                                            {data.contentfulPageKontakt.email}
                                        </SidebarLink>
                                        <SidebarLink href={`tel:${data.contentfulPageKontakt.phone1Number}`}>
                                            <PhoneIcon width={18} fill="#36355D" />
                                            {data.contentfulPageKontakt.phone1Number}
                                        </SidebarLink>

                                        {/*<SidebarLink*/}
                                        {/*    href={`https://booksy.com/pl-pl/158309_mag-med-rehabilitacja-fizjoterapia_fizjoterapia_23280_lodz`}*/}
                                        {/*>*/}
                                        {/*    <BooksyIcon width={70} fill="#36355D" style={{ marginLeft: 8 }} />*/}
                                        {/*    Umów on-line*/}
                                        {/*</SidebarLink>*/}
                                    </Aside>

                                    <Aside>
                                        <RecentPostsHeader>
                                            {data.contentfulPageCennik.sidebarZabiegiTytu}
                                        </RecentPostsHeader>
                                        <AsideServices>
                                            {data.allContentfulRehabilitacjaWpis.edges.map(({ node }) => {
                                                return (
                                                    <AsideServicesItem key={node.contentful_id}>
                                                        <LocaleLink to={`${ROUTES.REHABILITACJA}/${node.slug}`}>
                                                            {node.title}
                                                        </LocaleLink>
                                                    </AsideServicesItem>
                                                );
                                            })}
                                        </AsideServices>
                                    </Aside>
                                </div>
                            </ArticleLayout>
                        </PageRow>
                    </PageTransition>
                </Container>
            </PageContentWrapper>
        </>
    );
};

export default PricingPage;

export const pageQuery = graphql`
    query PricingQuery($node_locale: String) {
        contentfulPageCennik(contentful_id: { eq: "3xVAku7UMcfz74gIxZVIkJ" }, node_locale: { eq: $node_locale }) {
            title
            seo {
                title
                description
                noIndex
            }
            pojedynczyZabieg
            pakietPiciuZabiegw
            pakietDziesiciuZabiegw
            procedure
            currency
            megaPakietNagwek
            megaPakietOpis
            megaPakietCena
            rehabilitacjaKonsultacjaTytu
            rehabilitacjaKonsultacjaOpis {
                raw
            }
            sidebarZabiegiTytu
            specjalisciWCenniku {
                contentful_id
                id
                division {
                    id
                    contentfulid
                }
                name
                specialization
                cenaWizyty
                nfz
            }
            tabelaZabieg
            tabela1Zabieg
            tabela10Zabiegow
            tabelaNfz
            tabelaPakiet
            tabelaSpecjalista
            tytuSekcjiRehabilitacja
            tytuSekcjiSpecjalici
        }
        allContentfulCennikPozycja(
            filter: { node_locale: { eq: $node_locale } }
            sort: { fields: [category, label], order: ASC }
        ) {
            edges {
                node {
                    contentful_id
                    label
                    bundleCapacity
                    priceUnit
                    priceBundle
                    megaBundle
                    category
                    nfz
                }
            }
        }
        allContentfulPoradnia(filter: { node_locale: { eq: $node_locale } }, sort: { fields: title, order: ASC }) {
            edges {
                node {
                    title
                    id
                    contentfulid
                }
            }
        }
        allContentfulSpecjalisci(filter: { node_locale: { eq: $node_locale } }) {
            edges {
                node {
                    contentful_id
                    id
                    division {
                        id
                        contentfulid
                    }
                    name
                    specialization
                    cenaWizyty
                    nfz
                }
            }
        }
        contentfulPageRehabilitacja(
            contentful_id: { eq: "5tU4gA9JdKioNju1gHTbzE" }
            node_locale: { eq: $node_locale }
        ) {
            id
            title
            scheduleVisitLabel
            scheduleVisitDescription
        }
        allContentfulRehabilitacjaWpis(
            filter: { node_locale: { eq: $node_locale } }
            sort: { fields: title, order: ASC }
        ) {
            edges {
                node {
                    title
                    slug
                    contentful_id
                }
            }
        }
        contentfulPageKontakt(contentful_id: { eq: "4CI60KPsDdc3LGC7S3xH9k" }, node_locale: { eq: $node_locale }) {
            ...contact
        }
    }
`;
